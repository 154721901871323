
$(function () {
	Widget.init();
});
export var Widget = {
	init:function(){
		var exist = $('#widget_orders_stat_compare').length;
        if(exist>0){
			let currentYear = new Date().getFullYear();
			Widget.setOrdersStatCompare(currentYear, currentYear-1);
		}
	},
	setOrdersStatCompare(year_higher, year_lower){
		let partner_id = $("#partner_id").val();
		var ajax_url_higher = "/api/get-data-partners-statistics-v2/?key=GEr5v6yjGhj&partner_id="+partner_id+"&date_start=01.01."+year_higher+"&date_end=31.12."+year_higher;
		$.ajax({
			url: ajax_url_higher,
			dataType: "json",
			async: false,
			success: function (data) {
				console.log(data);
				$('#widget_orders_stat_compare_year_higher').html(year_higher);
				Widget.setValues('#widget_orders_stat_compare_higher', data.widget);
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) {
				alert('Got an Error');
			}
		}); 
		var ajax_url_lower = "/api/get-data-partners-statistics-v2/?key=GEr5v6yjGhj&partner_id="+partner_id+"&date_start=01.01."+year_lower+"&date_end=31.12."+year_lower;
		$.ajax({
			url: ajax_url_lower,
			dataType: "json",
			async: false,
			success: function (data) {
				console.log(data);
				$('#widget_orders_stat_compare_year_lower').html(year_lower);
				Widget.setValues('#widget_orders_stat_compare_lower', data.widget);
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) {
				alert('Got an Error');
			}
		}); 
	},
	setValues:function(item, data){
		$(item + " #widget_sum").html(data.sum.toLocaleString() + " ₽");
		$(item + " #widget_order_count").html(data.order_count.toLocaleString());
		$(item + " #widget_average_bill").html(data.average_bill.toLocaleString() + " ₽");
		$(item + " #widget_sum_m2").html(data.sum_m2.toLocaleString() + " м<sup>2</sup>");
	}
};