import {Widget} from "./widget";

$(function () {
    // Load the Visualization API and the corechart package.
    google.charts.load('current', { packages: ['line'],'language': 'ru' });
    // Set a callback to run when the Google Visualization API is loaded.
    google.charts.setOnLoadCallback(function() {
        drawLineChartFunc('orders_stat', 7);
    });
    
    var currentYear = new Date().getFullYear();
    google.charts.setOnLoadCallback(function() {
        drawLineChartCompareFunc('orders_stat_compare', currentYear);
    });
    var exist = $('#orders_stat_period').length;
    if(exist>0){
        document.getElementById('orders_stat_period').onchange = function() {
            //console.log('orders_stat_period: '+$(this).val());
            drawLineChartFunc('orders_stat', $(this).val());
        };
    }
    var exist_compare = $('#orders_stat_period_compare').length;
    if(exist_compare>0){
        document.getElementById('orders_stat_period_compare').onchange = function() {
            //console.log('orders_stat_period_compare: '+$(this).val());
            let compare_year = $(this).val();
            drawLineChartCompareFunc('orders_stat_compare', compare_year);
            Widget.setOrdersStatCompare(compare_year, compare_year-1);
        };
    }
    // Load the Visualization API and the corechart package.
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(function() {
        drawPieChartFunc('top_collections');
    });
    // Load the Visualization API and the corechart package.
    google.charts.load('current', { packages: ['bar'],'language': 'ru' });
    // Set a callback to run when the Google Visualization API is loaded.
    google.charts.setOnLoadCallback(function() {
        drawColumnChartFunc('category_orders_stat', 7);
    });
    
});
    var drawAreaLineChartFunc = function(type, period, amount_type) {
        var partner_id = $('#partner_id').val();        
        var ajax_url = "/api/get-data-charts?type="+type+"&period="+period+"&amount_type="+amount_type+"&key=1&partner_id="+partner_id;
        var jsonData = null;
        var exist = $('#chart_'+type).length;
        if(exist>0){
            $.ajax({
                url: ajax_url,
                dataType: "json",
                async: false,
                success: function (data) {
                    var arrSales = [['Месяц', 'Продажи за '+period_1+' год', 'Продажи за '+(period_1-1)+' год']];    // Define an array and assign columns for the chart.
                    console.log(data);
                    // Loop through each data and populate the array.
                    $.each(data, function (index, value) {
                        console.log(index);
                        console.log(value);
                        arrSales.push([index, value.sales_1, value.sales_2]);
                    });

                    // Set chart Options.
                    var options = {
                        curveType: 'function',
                        // chart: {
                        //     title: 'Compare Sales',
                        //     subtitle: '-- quantities sold'
                        // },
                        vAxis: { 
                            format: 'currency'
                        },
                        axes: {
                            x: {
                                0: { side: 'bottom'}   // Use "top" or "bottom".
                            }
                        },
                        legend: { 
                            position: 'bottom' 
                        },
                        height: 400
                    };
                // Create DataTable and add the array to it.
                var data = google.visualization.arrayToDataTable(arrSales)

                // Define the chart type (LineChart) and the container (a DIV in our case).
                var chart = new google.charts.Line(document.getElementById('chart_'+type))

                // Draw the chart with Options.
                chart.draw(data, google.charts.Line.convertOptions(options));
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert('Got an Error');
                }
            });  
        }
        
    }
    // Callback that creates and populates a data table,
    // instantiates the pie chart, passes in the data and
    // draws it.
    var drawLineChartFunc = function(type, period) {
      //var type = 'request';
      //var period = 'month';
      var partner_id = $('#partner_id').val();        
      var ajax_url = "/api/get-data-charts?type="+type+"&period="+period+"&key=1&partner_id="+partner_id;
      console.log('drawLineChartFunc: '+ajax_url);
      
      var jsonData = null;
      var exist = $('#chart_'+type).length;
      if(exist>0){
          $.ajax({
                url: ajax_url,
                dataType: "json",
                async: false,
                success: function (data) {
                    var arrSales = [['Дата', 'Продажи']];    // Define an array and assign columns for the chart.

                    // Loop through each data and populate the array.
                    $.each(data, function (index, value) {
                        arrSales.push([index, value.sales]);
                    });

                    // Set chart Options.
                    var options = {
                        
                        vAxis: { 
                            format: 'currency'
                        },
                        axes: {
                            x: {
                                0: { side: 'bottom'}   // Use "top" or "bottom".
                            }
                        },
                        legend: { 
                            position: 'bottom' 
                        },
                        animation:{
                            duration: 1000,
                            easing: 'out',
                        },
                        curveType: 'function',
                        height: 400
                    };
                // Create DataTable and add the array to it.
                var data = google.visualization.arrayToDataTable(arrSales)

                // Define the chart type (LineChart) and the container (a DIV in our case).
                var chart = new google.charts.Line(document.getElementById('chart_'+type))

                // Draw the chart with Options.
                chart.draw(data, google.charts.Line.convertOptions(options));
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert('Got an Error');
                }
            });  
        }
    }
    // Callback that creates and populates a data table,
    // instantiates the pie chart, passes in the data and
    // draws it.
    var drawLineChartCompareFunc = function(type, period_1) {
        //var type = 'request';
        //var period = 'month';
        var partner_id = $('#partner_id').val();        
        var ajax_url = "/api/get-data-charts?type="+type+"&period_compare="+parseInt(period_1)+"&key=1&partner_id="+partner_id;
        // console.log('drawLineChartCompareFunc: '+ajax_url);
        
        var jsonData = null;
        var exist = $('#chart_'+type).length;
        if(exist>0){
            $.ajax({
                url: ajax_url,
                dataType: "json",
                async: false,
                success: function (data) {
                    var arrSales = [['Месяц', 'Продажи за '+period_1+' год', 'Продажи за '+(period_1-1)+' год']];    // Define an array and assign columns for the chart.
                    console.log(data);
                    // Loop through each data and populate the array.
                    $.each(data, function (index, value) {
                        console.log(index);
                        console.log(value);
                        arrSales.push([index, value.sales_1, value.sales_2]);
                    });

                    // Set chart Options.
                    var options = {
                        curveType: 'function',
                        // chart: {
                        //     title: 'Compare Sales',
                        //     subtitle: '-- quantities sold'
                        // },
                        vAxis: { 
                            format: 'currency'
                        },
                        axes: {
                            x: {
                                0: { side: 'bottom'}   // Use "top" or "bottom".
                            }
                        },
                        legend: { 
                            position: 'bottom' 
                        },
                        height: 400
                    };
                // Create DataTable and add the array to it.
                var data = google.visualization.arrayToDataTable(arrSales)

                // Define the chart type (LineChart) and the container (a DIV in our case).
                var chart = new google.charts.Line(document.getElementById('chart_'+type))

                // Draw the chart with Options.
                chart.draw(data, google.charts.Line.convertOptions(options));
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert('Got an Error');
                }
            });  
        }
    }
    var drawColumnChartFunc = function(type, period) {
      //var type = 'request';
      //var period = 'month';
      var partner_id = $('#partner_id').val();        
      var ajax_url = "/api/get-data-charts?type="+type+"&period="+period+"&key=1&partner_id="+partner_id;
      // console.log('drawColumnChartFunc: '+ajax_url);
      
      var jsonData = null;
      var exist = $('#chart_'+type).length;
      if(exist>0){
          $.ajax({
                url: ajax_url,
                dataType: "json",
                async: false,
                success: function (data) {
                    //var arrSales = [['Коллекция', 'Продажи']];    // Define an array and assign columns for the chart.
                    var arrSales = [];
                    // Loop through each data and populate the array.
                    $.each(data, function (index, value) {
                        arrSales.push([index, value.sales]);
                    });

                    // Set chart Options.
                    var options = {
                        vAxis: { 
                            format: 'currency'
                        },
                        legend: { 
                            position: 'bottom' 
                        },
                        bars: 'horizontal',
                        height: 400,
                        width: '100%',
                    };
                // Create DataTable and add the array to it.
                var data = google.visualization.arrayToDataTable(arrSales)

                // Define the chart type (LineChart) and the container (a DIV in our case).
                var chart = new google.charts.Bar(document.getElementById('chart_'+type))

                // Draw the chart with Options.
                chart.draw(data, google.charts.Bar.convertOptions(options));
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert('Got an Error');
                }
            });  
        }
    }
    var drawPieChartFunc = function(type) {       
      var ajax_url = "/api/get-data-charts?type="+type+"&key=1";
      // console.log('drawPieChartFunc: '+ajax_url);
      
      var jsonData = null;
      var exist = $('#chart_'+type).length;
      if(exist>0){
          $.ajax({
                url: ajax_url,
                dataType: "json",
                async: false,
                success: function (data) {
                    var arrSales = [['Дата', 'Продажи']];    // Define an array and assign columns for the chart.

                    // Loop through each data and populate the array.
                    $.each(data, function (index, value) {
                        arrSales.push([index, value.sales]);
                    });

                    // Set chart Options.
                    var options = {
                        pieHole: 0.5,
                        height: 400,
                        width: '100%',
                        slices: {
                            0: { color: '#4faee1' },
                            1: { color: '#57b0d3' },
                            2: { color: '#68b7b6' },
                            3: { color: '#84be87' },
                            4: { color: '#92be6d' },
                            5: { color: '#9fbe49' },
                            6: { color: '#9fbe3d' }
                          }
                    };
                // Create DataTable and add the array to it.
                var data = google.visualization.arrayToDataTable(arrSales)

                // Define the chart type (PieChart) and the container (a DIV in our case).
                var chart = new google.visualization.PieChart(document.getElementById('chart_'+type));
                //var chart = new google.charts.(document.getElementById('chart_'+type))

                // Draw the chart with Options.
                chart.draw(data, google.charts.Line.convertOptions(options));
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert('Got an Error');
                }
            });  
        }
    }